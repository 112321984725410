import "./App.css";

import Honduras from "./assets/Icono-Honduras.svg";
import CostaRica from "./assets/Icono-CostaRica.svg";

function App() {
  return (
    <div className="contenedor">
      <header className="app-header">
        <img
          src="https://aseguramehn.s3.amazonaws.com/Imagenes/AsegurameLogo.png"
          alt="asegurame-logo"
        />
      </header>
      <div className="contenedor-paises">
        <h1>Selecciona tu país</h1>
        <p>Vive la experiencia más fácil de cotizar y comparar seguros.</p>
        <div className="contenedor-grid">
          <a href="https://www.asegurame.hn">
            <div className="card">
              <img src={Honduras} alt="honduras-icon" />
            </div>
            <p>Honduras</p>
          </a>
          <a href="https://www.asegurame.cr">
            <div className="card">
              <img src={CostaRica} alt="honduras-icon" />
            </div>
            <p>Costa Rica</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
